/* for hiding process is not defined bug from console */
body
  > iframe[style*="2147483647"]:not([id="webpack-dev-server-client-overlay"]) {
  display: none;
}
.chart {
  height: 320px !important;
  width: 320px !important;
  justify-items: center;
  margin: auto;
}
.img-includer {
  background-image: url("../img/img_fondo_01.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0.9;
  background-position: center center;
}
.img-includer-1 {
  background-image: url("../img/fondonina.png");
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0.9;
  background-position: center center;
}
.img-includer-2 {
  background-image: url("../img/fondonina.png");
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0.9;
  background-position: center center;
}
.img-includer-3 {
  background-image: url("../img/fondonina.png");
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0.9;
  background-position: center center;
}
.areas-style {
  opacity: 0.9;
}
.color-font {
  color: white !important;
  text-align: center;
}
.drawer {
  position: fixed;
  z-index: 9999;
  transition: width 0s ease 0.3s, height 0s ease 0.3s,
    transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.drawer > * {
  transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86),
    opacity 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86),
    box-shadow 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.drawer.drawer-open {
  transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.drawer .drawer-mask {
  background: #000;
  opacity: 0;
  width: 100%;
  height: 0;
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86),
    height 0s ease 0.3s;
}
.drawer-content-wrapper {
  position: absolute;
  background: #fff;
}
.drawer-content {
  overflow: auto;
  z-index: 1;
  position: relative;
}
.drawer-handle {
  position: absolute;
  top: 72px;
  width: 41px;
  height: 40px;
  cursor: pointer;
  z-index: 0;
  text-align: center;
  line-height: 40px;
  font-size: 16px;
  display: none;
  justify-content: center;
  align-items: center;
  background: #fff;
}
.btn-rund {
  border-radius: 12px;
}
.drawer-handle-icon {
  width: 14px;
  height: 2px;
  background: #333;
  position: relative;
  transition: background 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.drawer-handle-icon:before,
.drawer-handle-icon:after {
  content: "";
  display: block;
  position: absolute;
  background: #333;
  width: 100%;
  height: 2px;
  transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.drawer-handle-icon:before {
  top: -5px;
}
.drawer-handle-icon:after {
  top: 5px;
}
.drawer-left,
.drawer-right {
  width: 0%;
  height: 100%;
}
.drawer-left .drawer-content-wrapper,
.drawer-right .drawer-content-wrapper,
.drawer-left .drawer-content,
.drawer-right .drawer-content {
  height: 100%;
}
.drawer-left.drawer-open,
.drawer-right.drawer-open {
  width: 100%;
}
.drawer-left.drawer-open.no-mask,
.drawer-right.drawer-open.no-mask {
  width: 0%;
}
.drawer-left {
  top: 0;
  left: 0;
}
.drawer-left .drawer-handle {
  right: -40px;
  box-shadow: 2px 0 8px rgba(0, 0, 0, 0.15);
  border-radius: 0 4px 4px 0;
}
.drawer-left.drawer-open .drawer-content-wrapper {
  box-shadow: 2px 0 8px rgba(0, 0, 0, 0.15);
}
.drawer-right {
  top: 0;
  right: 0;
}
.drawer-right .drawer-content-wrapper {
  right: 0;
}
.drawer-right .drawer-handle {
  left: -40px;
  box-shadow: -2px 0 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px 0 0 4px;
}
.drawer-right.drawer-open .drawer-content-wrapper {
  box-shadow: -2px 0 8px rgba(0, 0, 0, 0.15);
}
.drawer-right.drawer-open.no-mask {
  right: 1px;
  transform: translateX(1px);
}
.drawer-top,
.drawer-bottom {
  width: 100%;
  height: 0%;
}
.drawer-top .drawer-content-wrapper,
.drawer-bottom .drawer-content-wrapper,
.drawer-top .drawer-content,
.drawer-bottom .drawer-content {
  width: 100%;
}
.drawer-top .drawer-content,
.drawer-bottom .drawer-content {
  height: 100%;
}
.drawer-top.drawer-open,
.drawer-bottom.drawer-open {
  height: 100%;
}
.drawer-top.drawer-open.no-mask,
.drawer-bottom.drawer-open.no-mask {
  height: 0%;
}
.drawer-top .drawer-handle,
.drawer-bottom .drawer-handle {
  left: 50%;
  margin-left: -20px;
}
.drawer-top {
  top: 0;
  left: 0;
}
.drawer-top .drawer-handle {
  top: auto;
  bottom: -40px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 0 0 4px 4px;
}
.drawer-top.drawer-open .drawer-content-wrapper {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.drawer-bottom {
  bottom: 0;
  left: 0;
}
.drawer-bottom .drawer-content-wrapper {
  bottom: 0;
}
.drawer-bottom .drawer-handle {
  top: -40px;
  box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px 4px 0 0;
}
.drawer-bottom.drawer-open .drawer-content-wrapper {
  box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.15);
}
.drawer-bottom.drawer-open.no-mask {
  bottom: 1px;
  transform: translateY(1px);
}
.drawer.drawer-open .drawer-mask {
  opacity: 0.3;
  height: 100%;
  transition: opacity 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.drawer.drawer-open .drawer-handle-icon {
  background: transparent;
}
.drawer.drawer-open .drawer-handle-icon:before {
  transform: translateY(5px) rotate(45deg);
}
.drawer.drawer-open .drawer-handle-icon:after {
  transform: translateY(-5px) rotate(-45deg);
}

/* drawer */
.drawer {
  overflow: visible;
  z-index: 9989 !important;
}
.drawer .drawer-content-wrapper {
  width: 50vw;
  transform: translateX(0px);
}
.drawer .drawer-content-wrapper .drawer-handle {
  display: none !important;
}
@media (min-width: 320px) and (max-width: 767px) {
  .drawer .drawer-content-wrapper {
    width: 100vw;
  }
}
@media (max-width: 1023px) {
  .rec.rec-carousel-wrapper {
    display: none;
  }
}
@media (min-width: 1023px) {
  .hidden-desktop {
    display: none;
  }
}
@media (max-width: 765px) {
  .hidden-mobile {
    display: none;
  }
}
@media (min-width: 765px) {
  .hidden-text {
    display: none;
  }
}
/*@media (max-height: 800px) {
  .hidden-mobile-vertical {
    display: none;
  }
}
@media (min-height: 900px) {
  .hidden-text-vertical {
    display: none;
  }
}*/

/* modal */
.custom-modal header button {
  display: none;
}

/*input tag css */
.react-tag-input {
  width: 100%;
  background: #f4f5f7;
  border: 1px solid #e5e7eb;
  border-radius: 0.375rem;
  padding: 0;
}
.react-tag-input__input {
  font-size: 0.875rem;
  margin: 0;
  height: 3rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  background: transparent;
  color: #4c4f52;
}
.react-tag-input__input:focus {
  background: #ffffff;
}
.react-tag-input__input::placeholder,
.react-tag-input__input:-moz-placeholder,
.react-tag-input__input:-ms-input-placeholder,
.react-tag-input__input::-moz-placeholder,
.react-tag-input__input::-webkit-input-placeholder {
  font-size: 10px;
  font-weight: 400;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}
.react-tag-input__tag {
  margin: 0.175rem 0.375rem;
  color: #24262d;
}
.theme-dark .react-tag-input {
  color: #ebebeb;
  border-color: #4c4f52;
  background-color: #24262d;
}
.theme-dark .react-tag-input__input {
  color: #ebebeb;
}
.theme-dark .react-tag-input__input:focus {
  background-color: #24262d;
  border-color: #707275;
}

/* dropdown style */
.dropdown {
  display: none !important;
}

/* notification box css */

.notification-box {
  width: 20rem;
  height: 22rem;
}

.notification-search {
  width: 30rem;
  height: 22rem;
  align-items: center;
  justify-content: center;
}
.notification-content h6 {
  font-size: 13px;
  margin-bottom: 5px;
  -webkit-line-clamp: 1;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}
.notification-content p span {
  font-size: 11px;
}
/*Actividades*/
@import url("https://fonts.googleapis.com/css?family=Nunito:400,700");
* {
  transition: all 0.3s ease-out;
}

.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.container-img {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

h3 {
  color: #262626;
  font-size: 17px;
  line-height: 24px;
  font-weight: 700;
  margin-bottom: 4px;
}

p {
  font-size: 17px;
  font-weight: 400;
  line-height: 20px;
  color: #666666;
}
p.small {
  font-size: 14px;
}

.go-corner {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 32px;
  height: 32px;
  overflow: hidden;
  top: 0;
  right: 0;
  background-color: #0286c4;
  border-radius: 0 4px 0 32px;
}

.go-arrow {
  margin-top: -4px;
  margin-right: -4px;
  color: white;
  font-family: courier, sans;
}


.go-corner-d {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 32px;
  height: 32px;
  overflow: hidden;
  top: 0;
  right: 0;
  background-color: rgb(209 213 219);
  border-radius: 0 4px 0 32px;
}

.go-arrow-d {
  margin-top: -4px;
  margin-right: -4px;
  color: rgb(107 114 128);
  font-family: courier, sans;
}

.card1 {
  display: block;
  position: relative;
  max-width: 100%;
  background-color: #f2f8f9;
  border-radius: 4px;
  padding: 32px 24px;
  margin: 12px;
  text-decoration: none;
  z-index: 0;
  overflow: hidden;
}
.card1:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: -16px;
  right: -16px;
  background: #0286c4;
  height: 32px;
  width: 32px;
  border-radius: 32px;
  transform: scale(1);
  transform-origin: 60% 50%;
  transition: transform 0.25s ease-out;
}
.card1:hover:before {
  transform: scale(21);
}

.card1:hover p {
  transition: all 0.3s ease-out;
  color: rgba(255, 255, 255, 0.8);
}
.card1:hover h3 {
  transition: all 0.3s ease-out;
  color: #ffffff;
}


.card-d {
  display: block;
  position: relative;
  max-width: 100%;
  background-color: rgb(209 213 219);
  border-radius: 4px;
  padding: 32px 24px;
  margin: 12px;
  text-decoration: none;
  z-index: 0;
  overflow: hidden;
}
.card-d:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: -16px;
  right: -16px;
  background: #0286c4;
  height: 32px;
  width: 32px;
  border-radius: 32px;
  transform: scale(1);
  transform-origin: 60% 50%;
  transition: transform 0.25s ease-out;
}

/* Card button*/

.card-button {
  display: block;
  position: relative;
  max-width: 100%;
  background-color: #0286c4;
  border-radius: 4px;
  padding: 32px 24px;
  margin: 12px;
  text-decoration: none;
  z-index: 0;
  overflow: hidden;
}
.card-button:before {
  content: "";
  top: 50%;
  right: 50%;
  background: #128fc9;
  height: 50px;
  width: 50px;
  border-radius: 32px;
  transform: scale(1);
  transform-origin: 60% 50%;
  transition: transform 0.25s;
}
.card-button:hover:before {
  transform: scale(21);
  z-index: -1;
  position: absolute;
}

.card-button:hover p {
  transition: all 0.3s ease-out;
  color: rgba(255, 255, 255, 0.8);
}
.card-button:hover h3 {
  transition: all 0.3s ease-out;
  color: #ffffff;
}


/* Card button block*/

.card-button-block {
  display: block;
  position: relative;
  max-width: 100%;
  background-color: #94a3b8;
  border-radius: 4px;
  padding: 32px 24px;
  margin: 12px;
  text-decoration: none;
  z-index: 0;
  overflow: hidden;
}
.card-button-block:before {
  content: "";
  top: 50%;
  right: 50%;
  background: #94a3b8;
  height: 50px;
  width: 50px;
  border-radius: 32px;
  transform: scale(1);
  transform-origin: 60% 50%;
  transition: transform 0.25s;
}
.card-button-block:hover:before {
  transform: scale(21);
  z-index: -1;
  position: absolute;
}

.card-button-block:hover p {
  transition: all 0.3s ease-out;
  color: rgba(255, 255, 255, 0.8);
}
.card-button-block:hover h3 {
  transition: all 0.3s ease-out;
  color: #ffffff;
}

.description-part-block {
  color: #94a3b8;
}


.description-part {
  color: #0286c4;
}

.bg-educare {
  --bg-opacity: 1;
  background-color: #0286c4;
  background-color: rgba(2, 134, 196, var(--bg-opacity));
}
.educare {
  background-color: #0286c4;
}

form.texto-bco > label {
  color: white !important;
}

.color-ged,
.text-ged {
  color: #02628f;
}
.hover\:text-ged:hover {
  --tw-text-opacity: 1;
  color: rgb(2 98 143 / var(--tw-text-opacity));
}

.bg-color-ged {
  background-color: #02628f;
}
.btn-cerrar-1 {
  height: 36px;
  width: 36px;
  background: url("../img/close.svg") no-repeat center;
  background-size: cover;
  border-radius: 50% !important;
  top: 1rem;
  right: 1rem;
  display: block;
  border: solid white 2px !important;
}
.btn-cerrar-2 {
  height: 36px;
  width: 36px;
  background: url("../img/close2.svg") no-repeat center;
  background-size: cover;
  border-radius: 50% !important;
  top: 1rem;
  right: 1rem;
  display: block;
  border: solid #02628f 2px !important;
}
.block {
  display: block !important;
}

.opacity-75 {
  opacity: 0.75;
}

.opacity-90 {
  opacity: 0.9;
}
/* Estilos secundarios para marcos, botones, actividades, hovers, etc*/
.border-4 {
  border-width: 10px;
}
.bg-educare-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(2 134 196 / var(--tw-bg-opacity));
}
.hover\:bg-educare-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(3, 75, 109 / var(--tw-bg-opacity));
  /* background-color: rgb(3, 75, 109); */
}
.hover\:bg-educare-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(2 134 196 / var(--tw-bg-opacity));
}
.bg-educare-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(2 134 196 / var(--tw-bg-opacity));
}
.w-16 {
  width: 10rem;
}
.w-20 {
  width: 30rem;
}
.w-btn {
  width: 32rem;
}
.w-staff {
  width: 80rem;
}
.outline-white {
  outline: 2px solid white;
}
.outline-educare {
  outline: 2px solid rgb(2 134 196 / var(--tw-bg-opacity));
}
.bg-red-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(246 79 79 / var(--tw-bg-opacity));
}
.hover\:bg-red-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(249 76 76 / var(--tw-bg-opacity));
}
.duration-100 {
  transition-duration: 100ms;
}
.ring-black {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(78 170 214 / var(--tw-ring-opacity));
}
.text-educare-400 {
  --tw-text-opacity: 1;
  color: rgb(78 170 214 / var(--tw-text-opacity));
}
.bg-educare-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(78 170 214 / var(--tw-bg-opacity));
}
.shadow-sky-600 {
  --tw-shadow-color: #93c5fd;
  --tw-shadow: var(--tw-shadow-colored);
}
.w-card {
  width: 20rem;
}
.h-sign {
  height: auto;
}
.my-15 {
  margin-top: 5.5rem;
  margin-bottom: 2.5rem;
}
.pt-40 {
  padding-top: 8rem /* 160px */;
}
/*Estios group*/
.p-12 {
  padding: 3rem /* 48px */;
}
.rounded-xl {
  border-radius: 0.75rem /* 12px */;
}
.shadow-2xl {
  --tw-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25);
  --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}
.gap-6 {
  gap: 1.5rem /* 24px */;
}
@media (min-width: 1024px) {
  .lg\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }
}
.hover\:bg-red-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(239 68 68 / var(--tw-bg-opacity));
}

/************
.border-4 {
  border-width: 5px;
}
.w-16 {
  width: 10rem;
}
.btn-cerrar-1 {
  height: 36px;
  width: 36px;
  background: url("../img/close.svg") no-repeat center;
  background-size: cover;
  border-radius: 50% !important;
  top: 1rem;
  right: 1rem;
  display: block;
  border: solid white 2px !important;
}
********************/

*::-webkit-scrollbar {
  width: 12px;
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background: #02628f;
  height: 150px;
  border-radius: 20px;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #ccc;
}

.tooltipcl {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltipcl .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltipcl .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltipcl:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
.left-1 {
  left: 0.65rem;
  box-shadow: 2px 5px 5px 1px rgba(51, 144, 196, 0.5);
}

.item-shadow {
  box-shadow: 2px 4px 10px 1px rgba(56, 75, 87, 0.5);
}

.item-shadow-group {
  box-shadow: 1px 1px 4px 1px rgba(56, 75, 87, 0.5);
}

.item-top-shadow {
  box-shadow: 6px 2px 5px 0px rgba(56, 75, 87, 0.5);
}

.item-left-shadow {
  box-shadow: 90px 3px 5px 0px rgba(56, 75, 87, 0.5);
}

.rounded-lg-quantity {
  border-radius: 0.5rem;
}
.text-lg-quantity {
  font-size: 1.1rem;
  line-height: 1.75rem;
}
.float-left {
  float: left;
}
.ml-6 {
  margin-left: 2.5rem;
}
/*Carousel*/
.cardi {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 250px;
  width: 100%;
  background-color: #683bb7;

  margin: 15px;
  background-color: #f9fafb;
  font-size: 4em;
}
.contain-carousel {
  font-family: sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f9fafb;
}

.seperator {
  width: 100%;
}

.rec.rec-carousel-wrapper {
  width: 100%;
  background-color: #f9fafb;
}
.rec.rec-slider-container {
  background-color: #f9fafb;
}
.rec.rec-carousel-item {
  background-color: #f9fafb;
}
.rec-carousel-item:focus {
  outline: none;
  box-shadow: inset 0 0 1px 1px #f9fafb;
}
.rec.rec-arrow-left {
  background-color: #0286c4;
  color: white;
}
.rec.rec-arrow-right {
  background-color: #0286c4;
  color: white;
}
.rec.rec-arrow:disabled {
  visibility: hidden;
}
.rec.rec-arrow:hover:enabled {
  color: #fff;
  background-color: rgb(11, 57, 145);
  box-shadow: 0 0 2px 0 #333;
}
.rec.rec-arrow:focus:enabled {
  background-color: rgb(11, 57, 145);
}
.rec.rec-dot_active {
  background-color: rgb(79, 129, 223);
  box-shadow: 0 0 1px 3px rgb(11, 57, 145);
}
.rec.rec-dot:hover:enabled {
  background-color: rgb(79, 129, 223);
  box-shadow: 0 0 1px 3px rgb(11, 57, 145);
}
.max-w-5xl {
  max-width: 70rem;
}
/*---Switch-------------------------------------------------------------*/
label {
  user-select: none;
}
.switch-wrapper > input[type="checkbox"] {
  opacity: 0;
  position: absolute;
}

.switch-wrapper > input[type="checkbox"] + .switch {
  transform: translateX(5px);
}
.switch-wrapper > input[type="checkbox"]:checked + .switch {
  transform: translateX(50%) translateX(-19px);
}
.switch-wrapper.large > input[type="checkbox"]:checked + .switch {
  transform: translateX(50%) translateX(-35px);
}
.switch-wrapper > input[type="checkbox"]:disabled + .switch {
  background: #ccc;
}

.switch-wrapper {
  border-radius: 20px;
  cursor: pointer;
  height: 24px;
  float: left;
  overflow: hidden;
  position: relative;
  width: 48px;
}
.switch-wrapper.large {
  height: 40px;
  width: 80px;
}
.switch-wrapper > .switch {
  color: #fff;
  display: inline-block;
  height: 100%;
  left: -100%;
  position: relative;
  transition: 100ms linear;
  width: 200%;
}
.switch-wrapper > .switch > .switch-handle {
  background: #fff;
  border-radius: 50%;
  display: inline-block;
  height: 14px;
  left: 50%;
  position: relative;
  top: 1px;
  width: 14px;
  z-index: 3;
}
.switch-wrapper.large > .switch > .switch-handle {
  height: 30px;
  width: 30px;
}
.switch-label {
  float: left;
  line-height: 2.5rem;
  margin-left: 10px;
}
.switch-wrapper.large + .switch-label {
  line-height: 4rem;
  margin-left: 15px;
}
.btn-danger {
  color: #fff;
  background-color: #d9534f;
  border-color: #d43f3a;
}
.btn-success:hover {
  color: #fff;
  background-color: #449d44;
  border-color: #398439;
}
.btn-success {
  color: #fff;
  background-color: #5cb85c;
  border-color: #4cae4c;
}
/************BORDER RADIUS ON MODAL LOGIN**************************/
.rounded-br-lgb {
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}
.rounded-br-lgt {
  border-top-right-radius: 0.5rem;
  border-top-left-radius: 0.5rem;
}
/************BURGER MENU**************************/
@keyframes checked-anim {
  0% {
    width: 1rem;
    height: 100%;
    border-radius: 0;
  }
  100% {
    width: 17rem;
    height: 100%;
    border-radius: 0;
  }
}
@keyframes not-checked-anim {
  0% {
    width: 90px;
    height: 90px;
  }
}
#burger:hover {
  transform: rotate(0) scale(1.2)
}
.burger-li,
.burger-a {
  margin: 5rem 0rem -3.4rem 3rem;
  color: #03a9f4;
  font: 14pt "Roboto", sans-serif;
  font-weight: 700;
  line-height: 1.8;
  text-decoration: none;
  text-transform: none;
  list-style: none;
  display: none;
}
.burger-li {
  width: 230px;
  text-indent: 56px;
  margin-left: 25px;
}
.burger-img {
  color: #03a9f4;
  list-style: none;
  position: absolute;
  padding: 2rem;
}
.burger-btn {
  margin-left: -25px;
}
.burger-a:focus {
  display: block;
  background-color: #eee;
  transition: all 0.5s;
}
body {
  overflow: hidden;
}

#trigger,
#burger,
#burger:before,
#burger:after {
  position: absolute;
  top: 25px;
  left: 25px;
  background: #03a9f4;
  width: 30px;
  height: 5px;
  transition: 0.2s ease;
  cursor: pointer;
  z-index: 1;
}
#trigger {
  height: 25px;
  background: none;
}
#burger:before {
  content: " ";
  top: 10px;
  left: 0;
}
#burger:after {
  content: " ";
  top: 20px;
  left: 0;
}
#menu-toggle:checked + #trigger + #burger {
  top: 35px;
  transform: rotate(180deg);
  transition: transform 0.2s ease;
}

#menu-toggle:checked + #trigger + #burger:before {
  width: 20px;
  top: -2px;
  left: 18px;
  transform: rotate(45deg) translateX(-5px);
  transition: transform 0.2s ease;
}
#menu-toggle:checked + #trigger + #burger:after {
  width: 20px;
  top: 2px;
  left: 18px;
  transform: rotate(-45deg) translateX(-5px);
  transition: transform 0.2s ease;
}
#menu {
  position: absolute;
  margin: 0;
  padding: 0;
  width: 60px;
  height: 60px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 100%;
  margin-top: 8px;
  margin-bottom: 10px;
  margin-left: 10px;
  margin-right: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.26);
  animation: not-checked-anim 0.1s both;
  transition: 0.1s;
}
#menu-toggle:checked + #trigger + #burger + #menu {
  animation: checked-anim 0.2s ease both;
  margin-top: -1px;
  margin-left: -1px;
}
#menu-toggle:checked + #trigger ~ #menu > li,
a {
  display: block;
}
[type="checkbox"]:not(:checked),
[type="checkbox"]:checked {
  display: none;
}
.mt-28 {
  margin-top: 20rem;
}
.h-34 {
  height: 9rem;
}

.bg-actividad {
  width: 100%;
  height: 100%;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("../img/bgactvidad.svg");
  background-repeat: repeat;
  background-size: cover;
  opacity: 0.9;
  background-position: center center;
}
/*MODAL ANIMACIÓN*/
.overlay-base {
  padding: 1rem;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0);
  opacity: 0;
  transition-property: background-color, opacity;
  transition-duration: 500ms;
  transition-timing-function: ease-in-out;
  outline: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.overlay-after {
  background-color: rgba(0, 0, 0, 0.6);
  opacity: 1;
  z-index: 11;
}

.overlay-after-mobile {
  background-color: rgba(0, 0, 0, 0);
  opacity: 1;
  z-index: 11;
}

.overlay-before {
  background-color: rgba(0, 0, 0, 0);
  opacity: 0;
}

.contenedor-base {
  position: relative;
  /*
  top: auto;
  left: auto;
  right: auto;
  bottom: auto;
  */
  margin: 0 auto;
  border: 0;
  outline: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 0%;
  width: 0%;
  background-color: transparent;
  transition-property: background-color, width, height;
  transition-duration: 500ms;
  transition-timing-function: ease-in-out;
}

.contenedor-after {
  width: 60rem;
  height: 30rem;
}

.contenedor-before {
  width: 0%;
  height: 0%;
  background-color: transparent;
}
/* --------Search Bar--------*/

input {
  outline: none;
}

input[type="searchs"] {
  font-family: inherit;
  font-size: 100%;
}
input::-webkit-search-decoration,
input::-webkit-search-cancel-button {
  display: none;
}
input[type="searchs"] {
  padding: 9px 10px 9px 32px;
  width: 15rem;
  background: #ededed
    url(https://static.tumblr.com/ftv85bp/MIXmud4tx/search-icon.png) no-repeat
    9px center;
  border: solid 1px #ccc;

  -webkit-border-radius: 10em;
  -moz-border-radius: 10em;
  border-radius: 10em;

  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s;
}
input[type="searchs"]:focus {
  width: 20rem;
  background-color: #fff;
  color: #000;
  border-color: #fff;

  -webkit-box-shadow: 0 0 5px rgba(2, 134, 196);
  -moz-box-shadow: 0 0 5px rgba(2, 134, 196);
  box-shadow: 0 0 5px rgba(2, 134, 196);
}
/* Estilos para tabla de carga masiva */
.border-separate {
  border-collapse: separate;
}
.border-spacing-0\.5 {
  --tw-border-spacing-x: 0.125rem /* 2px */;
  --tw-border-spacing-y: 0.125rem /* 2px */;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}
.h-96 {
  height: 24rem /* 384px */;
}
.sticky {
  position: sticky;
}
/* Estilos para Tabs de grupos */
.h-12 {
  height: 3rem /* 48px */;
}
.flex-col-tab {
  flex-direction: column;
}
.px-12 {
  padding-left: 3rem /* 48px */;
  padding-right: 3rem /* 48px */;
}
.w-tab {
  width: 60rem;
}
.b-tab {
  border-bottom: 4px solid #00aeef;
  border-radius: 0.125rem;
  width: auto;
  position: relative;
}
.transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.duration-500 {
  transition-duration: 500ms;
}
.h-48 {
  height: 12rem /* 192px */;
}
.p-12 {
  padding: 3rem /* 48px */;
}
.translate-x-0 {
  transform: translateY(0px);
}
.-translate-x-2 {
  transform: translateY(5.5rem);
}
.border-2 {
  border-width: 2px;
}
.li-buk {
  list-style-position: outside;
  font-size: 17px;
  font-weight: 400;
  color: #666666;
  line-height: 1.5;
  margin-left: 1.5rem;
  list-style: disc;
}
.space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.5rem * var(--tw-space-x-reverse));
  margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));
}
.pt-10 {
  padding: 5%;
}
.div-load {
  border: 5px solid hsla(199, 97%, 28%, 0.2);
  border-top-color: #1a9cd8;
  border-radius: 50%;
  width: 8em;
  height: 8em;
  text-align: center;
  justify-content: center;
  display: flex;
  animation: spin 2s linear infinite;
}
@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
.pt-50 {
  padding: 15%;
}
.pl-8 {
  padding-left: 2rem /* 32px */;
}
.h-96 {
  height: 96vh;
}

.pwd-icon {
  color: #0286c4;
  bottom: 25%;
  right: 5%;
}

.pwd-icon:hover {
  filter: brightness(1.2);
}

.pointer {
  cursor: pointer;
}


.widith-close-button {
  width: 9em;
}

.widith-confirm-button{
  width: 9em;
}

.onTop{
z-index: 11;
}

.centered {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 30;
}

.bottom-content {
  position: absolute;
  bottom: -80px;
  width: 100%;
  
}

.content-to-possition {
  position:sticky;
}


/* ========== Slideshow container ===========*/
#container-slider
{
    position: relative;
    display: block;
    width: 100%;
}
#slider {
    position: relative;
    display: block;
    width: 100%;
    height: 100vh;
    min-height: 500px;
}
#slider li {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    width: 100%;
    height: 100%;
    display: block;
    -webkit-transition: opacity 1s;
    -moz-transition: opacity 1s;
    -ms-transition: opacity 1s;
    -o-transition: opacity 1s;
    transition: opacity 1s;
    z-index: -1;
    opacity: 0;
}

.content_slider{
    padding: 15px 30px;
    color: #FFF;
    width: 100%;
    height: 100%;
}
.content_slider div{
    text-align: center;
}
#slider li .content_slider{
    background: rgba(0, 0, 0, 0.50);
    padding: 10px 125px;
}
.content_slider{
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: center;
    align-items: center;
}
.btnSlider{
    color: #FFF;
    font-size: 15pt;
    font-family: 'arial';
    letter-spacing: 1px;
    padding: 10px 50px;
    border: 1px solid #CCC;
    background: rgba(37, 40, 80, 0.55);
    border-radius: 31px;
    text-decoration: none;
    transition: .5s all;
}
.listslider {
    position: absolute;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: space-between;
    align-items: center;
    left: 50%;
    bottom: 5%;
    list-style: none;
    z-index: 2;
    transform: translateX(-50%);
}
.listslider li {
    border-radius: 50%;
    width: 10px;
    height: 10px;
    cursor: pointer;
    margin: 0 5px;
}
.listslider li a {
    background: #CCC;
    border-radius: 50%;
    width: 100%;
    height: 100%;
    display: block;
}

@media screen and (max-width: 460px){
	#container-slider .arrowPrev, #container-slider .arrowNext{
		font-size: 20pt;
	}
	#container-slider .arrowPrev{
		left: 15px;
	}
	#container-slider .arrowNext{
		right: 15px !important;
	}
	#slider{
		height: 400px;
		min-height: 400px;
	}
	#slider li .content_slider{
		padding: 10px 35px;
	}
	.btnSlider{
		padding: 10px 30px;
    	font-size: 10pt;
	}

}

.style-image {
  opacity: 1;
}

.highlight-title {
  text-decoration-thickness: 4px;
  text-decoration-color: #0ea5e9;
  font-weight: 700;
  --tw-text-opacity: 1;
  color: rgb(71 85 105 / var(--tw-text-opacity));

}

.center-items-fix {
  display: grid;
  align-items: center;
  justify-content: center;
  place-items: center;
}


#search_title {
  background: #0286c4;
  max-width: 300px;
  width: 280px;
  min-width: 200px;
  height: 40px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none; /* Sin bordes por defecto */
}

#search_title:focus {
  outline: none; /* Elimina el contorno por defecto */
  border: none; /* Asegura que no haya ningún borde */
  border-radius: 0; /* Asegura que no haya bordes redondeados */
  border-bottom: 3px solid #ffffff; /* Línea blanca debajo */
  box-shadow: none; /* Elimina cualquier box-shadow por defecto */
}

.text-color-other-group {
  background: #FF9F1C;
  text-decoration-color: #FF9F1C;
}

/* Animación de desvanecimiento */
.fade-in {
  opacity: 0;
  transition: opacity 0.8s ease-in-out;
}

.fade-in-visible {
  opacity: 1;
}







